<template>

  <v-row class="layaut">

    <v-col cols="4" class=" ">
      <h1 style="font-weight: 800;color: #ff1544" class="text--primary" >
        {{ titlepage || "" }}
      </h1>
      <p class="text--secondary">{{ subtitle || "" }}</p>
    </v-col>

    <v-col cols="8">
      <div class="actions">
        <slot></slot>
      </div>
    </v-col>
    
  </v-row>

</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "layaut",
  props: ["subtitle"],
  data() {
    return {
      show1: false,
    };
  },
  computed: {
    ...mapState(["titlepage"]),
  },
};
</script>
<style lang="scss">
.layaut {
  width: 100%;
  display: flex;

  align-items: flex-start;
}
.btn-primary-ocho {
  border-radius: 15px;
  box-shadow: 0px 4px 10px 5px rgba(255, 89, 0, 0.2);
}
.actions{
  display: flex;
  justify-content: flex-end;
  align-content: center;
}
</style>
