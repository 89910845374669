<template>
  <div class="General">
    <!-- header de titulo y acciones -->
    <headerLayaut subtitle="La primer imagen será la portada del evento">
      <v-btn
        color="primary"
        style="border-radius: 10px"
        class="mr-2"
        @click="sortDialog = true"
        >Ordenar</v-btn
      >
      <v-btn
        color="primary"
        style="border-radius: 10px"
        class="mr-2"
        @click="dialog = true"
        >agregar imagen</v-btn
      >
    </headerLayaut>
    <!-- contenido de la pagina -->
    <v-container fluid>
      <v-row v-if="loading">
        <!-- <transition-group name="list-complete" tag="v-row" v-if="loading"> -->
        <v-col cols="12" sm="6" lg="3" md="4">
          <v-img
            :src="Event.venueMap || imagedefaul"
            width="100%"
            height="200px"
            style="border-radius: 10px"
          >
            <template>
              <div class="actions-update">
                <v-btn text elevation="2" @click="saveImgVenueMap"
                  >Mapa del evento</v-btn
                >
              </div>
            </template>
          </v-img>
        </v-col>
        <v-col
          v-for="(item, index) in imagesAll"
          :key="index"
          cols="12"
          class="list-complete-item"
          sm="6"
          lg="3"
          md="4"
        >
          <v-img
            :src="item"
            :lazy-src="item"
            aspect-ratio="1"
            class="grey lighten-2"
            width="100%"
            height="200px"
            style="border-radius: 10px"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <template>
              <v-row class="fill-height ma-0" align="end" justify="end">
                <v-chip small dark color="rgba(0, 0, 0, 0.73)" class="ma-2"
                  >{{ index }} - imagen</v-chip
                >
                <div class="container-btn mr-1 mb-1">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        rounded
                        icon
                        color="#fff"
                        elevation="3"
                        @click="fnDeleteDIalog(item)"
                      >
                        <v-icon color="#ff472d" size="15px"
                          >fas fa-trash-alt</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Eliminar imagen</span>
                  </v-tooltip>
                </div>
              </v-row>
            </template>
          </v-img>
        </v-col>
        <!-- </transition-group> -->
      </v-row>
    </v-container>

    <v-dialog
      v-model="sortDialog"
      v-if="sortDialog"
      scrollable
      width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="sortDialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
          Ordenar imágenes
        </v-card-title>
        <v-divider> </v-divider>
        <v-card-text>
          <draggable
            v-model="arrayImgSort"
            group="people"
            @start="drag = true"
            @end="drag = false"
          >
            <div
              v-for="(element, index) in arrayImgSort"
              :key="index"
              class="grab"
            >
              <div class="list-wrap-img">
                <v-img
                  :src="element"
                  :lazy-src="element"
                  aspect-ratio="1"
                  class="grey lighten-2 my-2"
                  width="100px"
                  max-width="150px"
                  height="60px"
                  style="border-radius: 10px"
                >
                </v-img>

                <v-icon color="primary"> fa-solid fa-sort </v-icon>
              </div>

              <v-divider v-if="index < arrayImgSort.length - 1"></v-divider>
            </div>
          </draggable>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn
            color="primary"
            style="border-radius: 10px"
            @click="SaveSort"
            :loading="btnLoading"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" scrollable width="500px" persistent>
      <v-card>
        <v-card-title>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="dialog = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
          Agregar imágenes
        </v-card-title>
        <v-divider> </v-divider>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" v-if="preView.length > 0">
              <v-carousel height="250px" style="border-radius: 10px">
                <v-carousel-item
                  v-for="(item, i) in preView"
                  :key="i"
                  :src="item || imagedefaul"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                ></v-carousel-item>
              </v-carousel>
            </v-col>
            <v-col cols="12">
              <v-file-input
                accept="image/*"
                v-model="fileImg"
                filled
                rounded
                prepend-icon="far fa-file-image"
                style="border-radius: 10px"
                placeholder="Selecciona una imagen"
                chips
                @change="uploadImg"
                multiple
                label="Selecciona hasta 3 imagenes"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-progress-linear
            v-if="progress != 0"
            :value="progress"
          ></v-progress-linear>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn
            color="primary"
            style="border-radius: 10px"
            :loading="btnLoading"
            @click="addImageInGallery"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogVenue" scrollable width="500px" persistent>
      <v-card>
        <v-card-title>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="dialogVenue = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
          mapa del evento
        </v-card-title>
        <v-divider> </v-divider>
        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" v-if="preViewMap">
              <v-img :src="preViewMap || imagedefaul"></v-img>
            </v-col>
            <v-col cols="12">
              <v-file-input
                accept="image/*"
                v-model="fileVenue"
                filled
                rounded
                prepend-icon="far fa-file-image"
                style="border-radius: 10px"
                placeholder="Selecciona una imagen"
                chips
                @change="uploadImgVenue"
                label="Mapa del evento"
              ></v-file-input>
            </v-col>
          </v-row>
          <v-progress-linear
            v-if="progress != 0"
            :value="progress"
          ></v-progress-linear>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn
            color="primary"
            style="border-radius: 10px"
            :loading="btnLoading"
            @click="addImageVenueEvent"
            >Aceptar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleted" scrollable max-width="400px" persistent>
      <v-card>
        <v-card-title>
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            small
            absolute
            @click="dialogDeleted = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
          Eliminar esta imagen?
        </v-card-title>
        <v-divider> </v-divider>
        <v-card-text>
          <v-img
            :src="imageDeleted"
            height="250px"
            class="mt-4"
            style="border-radius: 10px"
          >
          </v-img>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn color="primary" style="border-radius: 10px" @click="fnDeleted"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import headerLayaut from "@/components/header-layaut.vue";
import draggable from "vuedraggable";
// import sortTemplate from "@/components/sortTemplate.vue";
import { mapActions, mapState } from "vuex";
import { db, fb } from "@/firebase";
import { map } from "leaflet";
export default {
  name: "galery",

  components: {
    headerLayaut,
    draggable,
    // sortTemplate
  },
  data() {
    return {
      dialogVenue: false,
      preView: [],
      preViewMap: null,
      sortDialog: false,
      dialog: false,
      dialogDeleted: false,
      fileImg: null,
      fileVenue: null,
      arrayImgSort: [],
      imageDeleted: null,
      progress: 0,
      btnLoading: false,
      Event: null,
      preView: [],
      arrayImg: [],
      arrayImgVenue: [],
      imagedefaul: require("@/assets/templateImage.svg"),
      loading: false,
    };
  },
  methods: {
    ...mapActions(["visibleSearch", "Alert_", "addBusiness", "_searchTerm"]),
    saveImgVenueMap() {
      this.dialogVenue = true;
    },
    addNewImageVenue() {
      return new Promise(async (resolve, reject) => {
        if (this.fileVenue) {
          this.arrayImgVenue = [];
          const image = this.fileVenue;
          //// console.debug(image);
          let uploadTask = fb
            .app()
            .storage(`${process.env.VUE_APP_EL_OCHO_BK}-vive`)
            .ref()
            .child(`venueMap/${this.selectedEvent[`.key`]}/${image.name}`)
            .put(image);
          await uploadTask.on(
            "state_changed",
            (snapshot) => {
              // this.progress =
              //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            },
            (error) => {
              //console.error(error);
              this.Alert_({
                text: "Ocurrió un error inesperado, inténtelo nuevamente.",
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                //// console.debug(downloadURL);
                db.collection("events").doc(this.selectedEvent[`.key`]).update({
                  venueMap: downloadURL,
                });
                this.arrayImgVenue.push(downloadURL);
              });
            }
          );

          resolve(this.arrayImgVenue);
        }
      });
    },
    addImageVenueEvent() {
      this.addNewImageVenue().then((Response) => {
        //// console.debug("Response", Response);
        setTimeout(() => {
          this.preViewMap = null;
          this.fileVenue = null;
          this.dialogVenue = false;
          this.Alert_({
            text: "Imagenes agregadas correctamente",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "success",
          });
        }, 3000);
      });
    },
    dialogFn(type) {
      if (type == "deleted") {
      }
      if (!type) {
      }
    },
    SaveSort() {
      this.btnLoading = true;

      db.collection("events")
        .doc(this.selectedEvent[`.key`])
        .update({
          images: this.arrayImgSort,
        })
        .then(() => {
          this.imageDeleted = null;
          this.Alert_({
            text: "El orden fue guardado",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "info",
          });
        })
        .catch(() => {
          this.Alert_({
            text: "Ocurrió un error inesperado, inténtelo nuevamente.",
            timeout: 2000,
            btn_closed: true,
            icon: false,
            iconType: "mdi-check",
            type: "error",
          });
        });
      this.btnLoading = false;
      this.sortDialog = false;
    },
    fnDeleteDIalog(item) {
      this.imageDeleted = item;
      //// console.debug(item);
      this.dialogDeleted = true;
    },
    fnDeleted() {
      if (this.imageDeleted) {
        db.collection("events")
          .doc(this.selectedEvent[`.key`])
          .update({
            images: fb.firestore.FieldValue.arrayRemove(this.imageDeleted),
          })
          .then(() => {
            this.dialogDeleted = false;
            this.imageDeleted = null;
            this.Alert_({
              text: "Imagen eliminada correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "info",
            });
          })
          .catch(() => {
            this.Alert_({
              text: "Ocurrió un error inesperado, inténtelo nuevamente.",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
          });
      }
    },
    addImageInGallery() {
      this.btnLoading = true;

      if (this.fileImg) {
        this.addNewImage().then((Response) => {
          setTimeout(() => {
            this.arrayImg = [];
            this.preView = [];
            this.fileImg = null;
            this.btnLoading = false;
            this.dialog = false;
            this.Alert_({
              text: "Imagenes agregadas correctamente",
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "success",
            });
          }, 3000);
        });
      } else {
        this.btnLoading = false;
        this.Alert_({
          text: "selecciona al menos una imagen",
          timeout: 2000,
          btn_closed: true,
          icon: false,
          iconType: "mdi-check",
          type: "error",
        });
      }
    },

    addNewImage() {
      return new Promise(async (resolve, reject) => {
        if (this.fileImg.length > 0) {
          this.arrayImg = [];

          for (let index = 0; index < this.fileImg.length; index++) {
            const image = this.fileImg[index];

            let uploadTask = fb
              .app()
              .storage(`${process.env.VUE_APP_EL_OCHO_BK}-vive`)
              .ref()
              .child(`gallery/${this.selectedEvent[`.key`]}/${image.name}`)
              .put(image);

            //// console.debug(index);
            await uploadTask.on(
              "state_changed",
              (snapshot) => {
                // this.progress =
                //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              },
              (error) => {
                //console.error(error);
                this.Alert_({
                  text: "Ocurrió un error inesperado, inténtelo nuevamente.",
                  timeout: 2000,
                  btn_closed: true,
                  icon: false,
                  iconType: "mdi-check",
                  type: "error",
                });
              },
              () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  db.collection("events")
                    .doc(this.selectedEvent[`.key`])
                    .update({
                      images: fb.firestore.FieldValue.arrayUnion(downloadURL),
                    });
                  this.arrayImg.push(downloadURL);
                });
              }
            );
          }

          resolve(this.arrayImg);
        }
      });
    },

    uploadImg(e) {
      this.preView = [];
      this.preViewMap = "";
      e.forEach((element) => {
        let objectURL = URL.createObjectURL(element);
        this.preView.push(objectURL);
        this.preViewMap = objectURL;
      });
    },
    uploadImgVenue(e) {
      this.preViewMap = "";
        let objectURL = URL.createObjectURL(e);
        this.preViewMap = objectURL;

    },
  },
  computed: {
    ...mapState(["selectedEvent", "user"]),
    imagesAll() {
      if (this.Event.images) {
        //// console.debug(this.Event.images);
        this.arrayImgSort = JSON.parse(JSON.stringify(this.Event.images));
        return this.Event.images;
      } else {
        return [];
      }
    },
  },
  watch: {},
  mounted() {
    this._searchTerm("");

    this.visibleSearch(false);

    this.$nextTick(() => {
      this.$binding(
        "Event",
        db.collection("events").doc(this.selectedEvent[`.key`])
      );
      this.loading = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.container-btn {
  background-color: rgba(0, 0, 0, 0.73);
  border-radius: 20px;
}
.grab {
  cursor: grab;
}
.list-wrap-img {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-complete-item {
  transition: all 0.5s ease-in-out;
  display: inline-block;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(20px);
}
.actions-update {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
